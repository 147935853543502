<template>
  <div class="closePermissionApproval">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name == 'closePermissionApproval'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane label="关闭权限审核" name="first"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-width="80px"
            >
              <el-form-item label="审核状态">
                <el-select
                  v-model="formInline.status"
                  placeholder="请选择审核状态"
                  clearable
                >
                  <el-option label="待审核" value="0"></el-option>
                  <el-option label="审核通过" value="1"></el-option>
                  <el-option label="审核不通过" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="省份">
                <el-select
                  v-model="formInline.province"
                  placeholder="请选择省份"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in provinceData"
                    :key="item.provinceId"
                    :label="item.provinceName"
                    :value="item.provinceId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学校">
                <el-select
                  v-model="formInline.tenantId"
                  @change="changeTen"
                  placeholder="请选择学校"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in allSchoolData"
                    :key="item.tenantId"
                    :label="item.tenantName"
                    :value="item.tenantId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="教学点">
                <el-select
                  v-model="formInline.stationName"
                  placeholder="请选择教学点"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="(item, index) in correspondenceStation"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="入学批次">
                <el-select v-model="formInline.batch" @change="schoolTip" filterable placeholder="入学批次">
                  <el-option
                    v-for="item in schoolBatchData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学校性质">
                <el-select v-model="formInline.nature" filterable placeholder="学校性质">
                  <el-option
                    v-for="(item, key) in $common.schoolNatureData"
                    :key="key"
                    :label="item"
                    :value="key">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="提交时间">
                <el-date-picker
                  v-model="timeVal"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <el-button class="mainBtn" @click="addAudit" plain v-allow="'fin_close_audit_add'">+添加审批单</el-button>
            <el-button class="mainBtn" @click="exportEvent" plain v-allow="'fin_close_audit_export'">导出</el-button>
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
            row-key='id'
            @selection-change="handleSelectionChange">
            <el-table-column
              align="center"
              type="selection"
              :reserve-selection="true"
              width="55">
            </el-table-column>
            <el-table-column
              v-for="(item, index) in columnList"
              :key="index"
              :prop="item.prop"
              :label="item.label ? item.label : '/'"
              :width="item.width"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row[scope.column.property] == null">/</span>
                <span @click="aEvent(scope.row)" v-else-if="scope.column.property == 'status'" style="cursor: pointer;" :class="{'blue-btn': scope.row.status == '1','aStatus': scope.row.status == '2', 'fee': scope.row.status == '0'}">{{$common.closeLarningStatus[scope.row[scope.column.property]]}}</span>
                <span v-else-if="scope.column.property == 'refundStatusData'">{{$common.refundStatusData[scope.row[scope.column.property]]}}</span>
                <span v-else-if="scope.column.property == 'remark' || scope.column.property == 'provinceName'">
                  <el-tooltip :content="scope.row[scope.column.property]" placement="top" effect="light" popper-class="pubToop">
                    <div class="ellipsis">{{scope.row[scope.column.property] || '/'}}</div>
                  </el-tooltip>
                </span>
                <span v-else-if="scope.column.property == 'nature'">{{$common.schoolNatureData[scope.row.nature]}}</span>
                <span v-else-if="scope.column.property == 'createTime'">{{$way.timestampReturnDate(scope.row.createTime, "datetime") || "/"}}</span>
                <span v-else>
                  {{ scope.row[scope.column.property] }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="160"
              align="center">
              <template slot-scope="scope">
                <template v-if="scope.row.status == 2">
                  <el-button type="text" @click="jumpAudit('edit', scope.row)" v-allow="'fin_close_audit_edit'">编辑</el-button>
                  <el-button type="text" @click="handleEvent('reset', scope.row)">重新提交</el-button>
                </template>
                <!-- <el-button type="text" @click="jumpAudit('audit', scope.row)" v-if="scope.row.status == 0" v-allow="'fin_close_audit_audit'">前往审核</el-button> -->
                <el-button type="text" @click="jumpAudit('watch', scope.row)">详情</el-button>
                <el-button @click="handleEvent('delete', scope.row)" type="text" v-if="scope.row.status == 0" v-allow="'fin_close_audit_del'">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>
        <Keydown @keydownEvent='handleKeydown'></Keydown>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>

    <dialogDiy :isShow="isShow" :dialogDiyData="dialogDiyData" @dialogEvent="dialogDiyEvent">
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="@/assets/img/warn.png" alt="">
        <p>{{handleType == 'delete' ? '确定要删除该订单吗？' : '确定要重新提交该审批单吗？'}}</p>
        <div class="btn_area">
          <el-button @click="dialogDiyEvent">关闭</el-button>
          <el-button class="mainBtn" @click="doEvent">确定</el-button>
        </div>
      </div>
    </dialogDiy>
    <dialogSubmit :isShowSub="isShowSub" @closeSub="closeSub"></dialogSubmit>

    <!-- 添加审批单 -->
    <addApprovalClose ref="addApproval" @closeAdd="closeAdd"></addApprovalClose>

    <dialogDiy
      :isShow="isResShow"
      :dialogDiyData="dialogResDiyData"
      @dialogEvent="dialogResDiyEvent"
    >
      <div slot="dialogContent" class="result_area">
        <img src="@/assets/img/enter.png" alt="" />
        <span>您的请求已提交</span>
        <span
          >稍后请在
          <span @click="jumpTask" class="myWork">我的任务</span>
          中查看处理结果！</span
        >
      </div>
    </dialogDiy>
  </div>
</template>

<script>
import Pagination from '../../Pagination'
import publicChildren from '../../publicChildren'
import dialogDiy from '../../commonModule/dialogDiy'
import dialogSubmit from '../../commonModule/dialogSubmit'
import addApprovalClose from './addApprovalClose'
export default {
  name: 'closePermissionApproval',
  components: {
    Pagination,
    dialogDiy,
    dialogSubmit,
    addApprovalClose,
    publicChildren
  },
  data() {
    return {
      activeName: 'first',
      formInline: {
        batch: null,
        endTime: null,
        page: 1,
        pageSize: 10,
        province: null,
        startTime: null,
        stationName: null,
        status: null,
        tenantId: null,
        nature: null
      },
      provinceData: [],
      correspondenceStation: [],
      allSchoolData: [],
      schoolBatchData: [],
      timeVal: [],
      idArr: [],
      columnList: [
        {
          prop: "provinceName",
          label: "省份",
          width: 120
        },
        {
          prop: "schoolName",
          label: "学校"
        },
        {
          prop: "nature",
          label: "学校性质",
          width: 100
        },
        {
          prop: "admissionBatch",
          label: "批次",
          width: 100
        },
        {
          prop: "stationName",
          label: "教学点"
        },
        {
          prop: "studentNum",
          label: "学生数量"
        },
        {
          prop: "status",
          label: "审批状态"
        },
        // {
        //   prop: "status",
        //   label: "退款状态"
        // },
        {
          prop: "remark",
          label: "备注"
        },
        {
          prop: "submitBy",
          label: "提交人"
        },
        {
          prop: "createTime",
          label: "提交时间"
        }
      ],
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      rowData: {},
      handleType: null,
      isShow: false,
      dialogDiyData: {
        title: '',
        sizeStyle: {width: '400px',height: '234px'}
      },
      isShowSub: false,
      isResShow: false,
      dialogResDiyData: {
        title: "",
        sizeStyle: { width: "400px", height: "204px" },
      },
    }
  },
  created() {
    this.getProviceEvent()
    this.getTenantDown()
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal.name == 'closePermissionApproval') {
          this.getTheDropDownBoxData()
          this.getFincloseverifyList()
        }
      },
    },
  },
  methods: {
    handleKeydown(event) {
      if (!this.isShow&&!this.isShowSub&&!this.isResShow&&!this.$refs.addApproval.isShowAdd) {
        this.onSubmit()
      }
    },
    schoolTip() {
      if(!this.formInline.tenantId) {
        this.Warn('请先选学校')
        return
      }
    },
    changeTen() {
      if(!this.formInline.tenantId) return
      this.formInline.stationId = null
      this.formInline.batch = null
      this.getTheDropDownBoxData();
      this.getGoSchoolData();
    },
    // 获取入学批次数据
    getGoSchoolData() {
      let obj = {
        tenantId: this.formInline.tenantId
      }
      this.$request.getGoSchool(obj, res => {
        if(res.code == 0) {
          this.schoolBatchData = res.data;
        }
      })
    },
    // 获取省份
    getProviceEvent() {
      this.$request.getProvice({}, res => {
        if(res.code == 0) {
          this.provinceData = res.data;
        }
      })
    },
    // 获取教学点数据
    getTheDropDownBoxData() {
      let obj = {
        tenantId: this.formInline.tenantId
      }
      this.$request.getTheDropDownStr(obj, res => {
        if(res.code == 0){
          this.correspondenceStation = res.data;
        }
      })
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, res => {
        if(res.code == 0) {
          this.allSchoolData = res.data;
        }
      })
    },
    onSubmit() {
      if(this.timeVal) {
        this.formInline.startTime = this.timeVal[0];
        this.formInline.endTime = this.timeVal[1];
      }
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getFincloseverifyList();
    },
    // 重置
    resetEvent() {
      this.timeVal = []
      this.correspondenceStation = []
      this.schoolBatchData = []
      Object.assign(this.$data.formInline, this.$options.data().formInline)
      this.getTheDropDownBoxData()
    },
    handleSelectionChange(val) {
      this.idArr = val.map((item, index) => {
        return item.id
      })
    },
    getFincloseverifyList() {
      this.$request.fincloseverifyList(this.formInline).then( res => {
        if(res.data.code == 0){
          let {records, current, size, total} = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          }
        }
      })
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getFincloseverifyList();
    },
    dialogDiyEvent() {
      this.isShow = !this.isShow
    },
    closeSub(val) {
      this.isShowSub = val.isShow
    },
    handleEvent(type, row) {
      this.handleType = type
      this.rowData = row
      this.dialogDiyEvent()
    },
    doEvent() {
      if(this.handleType == 'delete') {
        this.deleteEvent()
      } else {
        this.resetSubEvent()
      }
    },
    // 删除审批单
    deleteEvent() {
      this.$request.deleteFincloseverify({id: this.rowData.id}).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg)
          this.getFincloseverifyList()
          this.dialogDiyEvent()
        }
      })
    },
    // 重新提交
    resetSubEvent() {
      this.$request.againFincloseverify({id: this.rowData.id}).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg)
          this.getFincloseverifyList()
          this.dialogDiyEvent()
        }
      })
    },
    // 添加审批单
    addAudit() {
      this.$refs.addApproval.showEvent()
    },
    jumpAudit(type, row) {
      let  query = {
        id: row.id,
        status: row.status,
        type,
        headTitle: type == 'edit' ? '编辑审核详情' : '审核详情'
      }
      this.$router.push({name: 'permissionDetail', query})
    },
    exportEvent() {
      if(this.idArr.length == 0) {
        this.Warn('请选择要导出的数据')
        return
      }
      let ids = this.idArr;
      this.$request.batchExportFincloseverify({ ids }).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.isResShow = !this.isResShow
        }
      })
    },
    closeAdd() {
      this.isResShow = !this.isResShow
      this.getFincloseverifyList()
    },
    dialogResDiyEvent(val) {
      this.isResShow = val.isShow
    },
    jumpTask() {
      this.$store.commit("notActiveEvent", true);
      sessionStorage.removeItem("key");
      this.$router.push("/Home/taskCenter");
    },
    aEvent(row) {
      if (row.status == '0') {
        if(this.$isShowModle('fin_close_audit_audit'))
          this.jumpAudit('audit', row)
        else
          this.Warn('暂无该权限')
      } else if (row.status == 1) {
        this.jumpAudit('watch', row)
      } else if (row.status == 2) {
        if(this.$isShowModle('fin_close_audit_edit'))
          this.jumpAudit('edit', row)
        else
          this.Warn('暂无该权限')
        // this.auditId = row.id
        // this.auditType = 'edit'
        // this.closeAdd()
      }
    },
  }
}
</script>

<style lang='less'>
  .closePermissionApproval {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      .table_btn_area {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
        border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .pop_del {
        margin-left: 6px;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .dia_opera {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
      .errIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .result_area {
      // width: 400px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      span {
        color: #777777;
        font-size: 18px;
      }
      span:nth-of-type(1) {
        margin: 18px 0px;
      }
      .myWork {
        cursor: pointer;
        font-weight: 600;
        color: #1282ff;
      }
    }
  }
</style>
<style lang="less" scoped>
  .blue-btn {
    color: #1282ff;
  }
  .aStatus {
    color: orange;
  }
  .fee {
    color: red;
  }
</style>
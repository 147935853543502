<template>
  <dialogDiy :isShow="isShowAdd" :dialogDiyData="dialogAddDiyData"
    @dialogEvent="dialogAddDiyEvent">
    <div slot="dialogContent" class="result_area_add">
      <el-scrollbar class="elScroll">
        <div class="scrollArea">
          <div class="ordereArea area">
            <p class="area_head">选择订单范围</p>
            <div class="selectArea">
              <span class="is-write">* </span>
              <el-select v-model="form.tenantId" placeholder="学校" filterable @change="changeTen">
                <el-option
                  v-for="item in allSchoolData"
                  :key="item.tenantId"
                  :label="item.tenantName"
                  :value="item.tenantId">
                </el-option>
              </el-select>
              <span class="is-write">* </span>
              <el-select v-model="form.stationId" placeholder="教学点" @focus="hasEvent">
                <el-option
                  v-for="item in correspondenceStation"
                  :key="item.stationsId"
                  :label="item.stationsName"
                  :value="item.stationsId">
                </el-option>
              </el-select>
              <span class="is-write">* </span>
              <el-select v-model="form.admissionBatch" placeholder="批次" @focus="hasEvent">
                <el-option
                  v-for="item in schoolBatchData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="uploadArea area">
            <p class="area_head">上传学生名单</p>
            <div class="small_upload">
              <div class="opera_left">
                <img src="@/assets/img/file.png"/>
                <span>学生名单样例</span>
                <el-button class="mainBtn" @click="downLoadEvent">点击下载</el-button>
              </div>
              <div class="opera_right">
                <div class="bor" @click="getLocalFile" v-if="!form.studentListUrl">
                  <img src="@/assets/img/adds.png"/>
                  <span>学生名单样例</span>
                </div>
                <div class="bor noBorder" @click="getLocalFile" v-else>{{showFileName}}</div>
                <!-- <el-button class="mainBtn" @click="">确认上传</el-button> -->
              </div>
            </div>
          </div>
          <div class="uploadDingArea area">
            <p class="area_head">上传审批凭证</p>

            <el-upload
              action="#"
              class="elUploads"
              list-type="picture-card"
              ref="uploads"
              accept="image/png, image/jpeg, image/jpg"
              :auto-upload="false"
              :limit="5"
              :on-exceed="exceedUpload"
              :on-change="changeEvent"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>

          </div>
          <!-- <div class="refundArea area">
            <p class="area_head">审批单退款</p>
            <div class="area-cont">
              <div class="refundRadio">
                <span>是否退款</span>
                <el-radio-group v-model="form.invoiceFlag">
                  <el-radio v-for="(item, key, index) in $common.invoiceFlagData" :key="index" :label="Number(key)">{{item}}</el-radio>
                </el-radio-group>
              </div>
              <el-form :inline="true" :model="form" class="refund-form" label-position="top" label-width="80px" v-if="form.invoiceFlag == 1">
                <el-form-item label="收款账号">
                  <el-input @input="trimLR('invoiceAmount')" v-model="form.invoiceAmount" clearable></el-input>
                </el-form-item>
                <el-form-item label="收款银行">
                  <el-input @input="trimLR('invoiceAmount')" v-model="form.invoiceAmount" clearable></el-input>
                </el-form-item>
              </el-form>
              <p style="margin-bottom: 10px;" v-if="form.invoiceFlag == 1">注：审批通过后，学生的个人缴费将于1-3个工作日，原路返还至原账户，个人缴费时间超过30天和学校統缴退款，需填收款账户信息</p>
            </div>
          </div> -->
          <div class="remarksArea area">
            <p class="area_head">备注</p>
            <el-input
              class="text_re"
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="form.remark">
            </el-input>
          </div>
        </div>
      </el-scrollbar>
      <div class="btnArea">
        <el-button @click="cancelEvent">取 消</el-button>
        <el-button @click="addAudit" class="enter_btn" type="primary">确 认</el-button>
      </div>
      <el-image-viewer
        v-if="showViewer"
        :on-close="watchEvent"
        :url-list="voucherUrlsPic"
      />
      <input style="display: none;" type="file" @change="fileChange()" ref="loadFile"/>
       <!-- accept=" application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  -->
      <input style="display: none;" type="file" @change="picChange()" ref="loadPic" accept="image/*" />
    </div>
  </dialogDiy>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import dialogDiy from '@/components/commonModule/dialogDiy.vue'
import { excelFileUpload } from '@/api/fileUpload'
const OSS = require('ali-oss');
export default {
  name: 'addApproval',
  components: {
    dialogDiy,
    ElImageViewer
  },
  props: ['isShowAddApp'],
  data() {
    var validNumber = (rule, value, callback) => {
      if(!this.$way.regNumber(value)) {
        callback(new Error("该字段为数字类型"));
      } else {
        callback();
      }
    }
    return {
      form: {
        admissionBatch: null,
        fileName: null,
        remark: null,
        stationId: null,
        studentListUrl: null,
        tenantId: null,
        voucherUrls: []
      },
      options: [],
      isShowAdd: false,
      dialogAddDiyData: {
        title: '添加审批单',
        sizeStyle: {width: '700px',height: '750px'}
      },
      allSchoolData: [],
      correspondenceStation: [],
      schoolBatchData: [],
      showFileName: '',
      showViewer: false,
      voucherUrlsPic: [],
      fileData: [],
    }
  },
  watch: {
    isShowAddApp: {
      immediate: true,
      handler(newVal, oldVal) {
        this.isShowAdd = newVal;
      }
    },
  },
  created() {
    this.getTenantDown();
  },
  methods: {
    trimLR(name) {
      this.form[name] = this.$way.clearSpace(this.form[name]);
      let arr = ['amountActual', 'invoiceAmount']
      if (arr.includes(name) && !this.$way.regNumber(this.form[name])) {
        this.Warn('该项请填数字')
        this.form[name] = null
      }
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, res => {
        if(res.code == 0) {
          this.allSchoolData = res.data;
        }
      })
    },
    // 获取教学点数据
    getTheDropDownBoxData() {
      let obj = {
        tenantId: this.form.tenantId
      }
      this.$request.getTheDropDownBoxs(obj, res => {
        if(res.code == 0){
          this.correspondenceStation = res.data;
        }
      })
    },
    changeTen() {
      this.form.stationId = null;
      this.form.admissionBatch = null;
      this.getTheDropDownBoxData();
      this.getGoSchoolData();
    },
    hasEvent() {
      if(!this.form.tenantId) {
        this.Warn('请先选学校');
        return;
      }
    },
    // 获取入学批次数据
    getGoSchoolData() {
      let obj = {
        tenantId: this.form.tenantId
      }
      this.$request.getGoSchool(obj, res => {
        if(res.code == 0) {
          this.schoolBatchData = res.data;
        }
      })
    },
    watchEvent(urlArr) {
      this.showViewer = !this.showViewer;
      if(this.showViewer) {
        this.voucherUrlsPic = urlArr;
      }
    },
    dialogAddDiyEvent(val) {
      this.isShowAdd = val.isShow;
      this.showEvent;
      this.clearEvent();
    },
    // 下载模板
    downLoadEvent() {
      // this.$request.getAuditModule(res=>{
      //   if(res.code == 0) {
      //     window.location.href = res.data;
      //   }
      // })
      this.$request.getModule({ labelName: 'verifyStudentImport' }).then((res) => {
        if (res.data.code == 0) {
          window.location.href = res.data.data;
        }
      });
    },
    getLocalFile() {
      this.$refs.loadFile.value = '';
      this.$refs.loadFile.click();
    },
    getLocalPic() {
      this.$refs.loadPic.value= '';
      this.$refs.loadPic.click();
    },
    async fileChange() {
      let inputDOM = this.$refs.loadFile;
      let files = inputDOM.files[0];
      let obj = await excelFileUpload(files);
      if(obj) {
        this.form.fileName = obj.fileName;
        this.showFileName = obj.showFileName;
        this.form.studentListUrl = obj.excelUrl;
      }
    },
    // async picChange() {
    //   let inputDOM = this.$refs.loadPic;
    //   let files = inputDOM.files[0];
    //   // this.picName = files.name;
    //   let ossUrl = await way.uploadOss(OSS, files, 'img/auditManagement');
    //   console.log(ossUrl);
    //   this.form.voucherUrls.push(ossUrl);
    // },
    exceedUpload() {
      this.Warn('最大图片上传数量为5')
    },
    async changeEvent(file, fileList) {
      // console.log(file, fileList,2223222222);
      // if (file.raw.size > 1024 * 100) {
      //   this.$message.error("海报大于100KB,不能上传");
      //   return;
      // }
      this.fileData = fileList;
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      this.fileData = fileList;
    },
    handlePictureCardPreview(file) {
      // console.log(file,985555)
      this.voucherUrlsPic = [file.url];
      this.showViewer = true;
    },
    // 确认
    addAudit() {
      if(this.fileData.length != 0) {
        let arr = this.fileData.map(item=>item.raw)
        // 多图片上传
        this.$request.uploadPics(arr).then( res=>{
          if(res.data.code == 0) {
            this.form.voucherUrls = res.data.data;
            this.addAuditEvent();
          }
        })
      } else {
        this.addAuditEvent()
      }
    },
    // 添加审批
    addAuditEvent() {
      if(!this.form.tenantId) {
        this.Warn('请选择学校');
        return;
      }
      if(!this.form.stationId) {
        this.Warn('请选择教学点');
        return;
      }
      if(!this.form.admissionBatch) {
        this.Warn('请选择批次');
        return;
      }
      this.$request.addFincloseverify(this.form).then( res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg)
          this.showEvent()
          this.$emit('closeAdd')
          this.clearEvent()
        }
      })
    },
    clearEvent() {
      Object.assign(this.$data.form, this.$options.data().form)
      this.fileData = []
      this.form.fileName = null
      this.showFileName = null
    },
    cancelEvent() {
      this.clearEvent();
      this.showEvent()
    },
    showEvent() {
      this.isShowAdd = !this.isShowAdd
    }
  },
}
</script>

<style lang="less">
  .result_area_add {
    height: calc(~'100% - 70px');
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    padding-bottom: 20px;
    .elScroll {
      width: 100%;
      height: 100%;
      .scrollArea {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0px 50px 10px;
      }
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .btnArea {
      width: 100%;
      height: 40px;
      text-align: right;
      padding: 20px 0px 0px;
      .enter_btn {
        margin-right: 50px;
      }
    }
    .area {
      width: 100%;
      border-bottom: 1px dashed #AAAAAA;
      .area_head {
        box-sizing: border-box;
        padding-left: 10px;
        margin-top: 28px;
        border-left: 3px solid #1282FF;
        color: #1282FF;
        font-size: 18px;
      }
    }
    .payTypeArea {
      padding-bottom: 20px;
      .el-radio-group {
        margin-top: 20px;
      }
      .el-form  {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .el-form-item {
          display: inline-block;
          width: 180px;
          .el-date-editor {
            width: 180px;
          }
          .times {
            .el-input__inner {
              padding-right: 18px;
            }
          }
        }
      }
    }
    .ordereArea {
      .selectArea {
        margin: 20px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .is-write {
          color: red;
        }
        .el-select {
          width: 30%;
        }
      }
    }
    .uploadArea {
      .small_upload {
        display: flex;
        box-sizing: border-box;
        padding: 30px;
        .opera_left, .opera_right {
          height: 180px;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
        }
        .opera_left {
          width: 200px;
          > img {
            width: 51px;
            height: 51px;
          }
          > span {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
        }
        .opera_right {
          width: 188px;
          .bor {
            width: 188px;
            height: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            border: 2px dashed #E1E1E1;
            cursor: pointer;
            > img {
              width: 25px;
              height: 25px;
            }
            > span {
              font-size: 14px;
              font-weight: 400;
              color: #999999;
            }
          }
          .noBorder {
            word-wrap:break-word;
            border: 0 none;
          }
        }
      }
    }
    .uploadDingArea {
      .elUploads {
        display: flex;
        justify-content: center;
        margin: 20px 0px;
        .el-upload-list__item {
          width: 80px;
          height: 80px;
        }
        .el-upload {
          width: 80px;
          height: 80px;
          display: inline-block;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .upload_area {
        margin: 20px 0px;
        height: 150px;
        display: flex;
        justify-content: center;
        .up_box {
          display: inline-block;
          width: 150px;
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px dashed #E1E1E1;
          border-radius: 5px;
          cursor: pointer;
          img {
            width: 45px;
            height: 45px;
          }
        }
      }
    }
    .remarksArea {
      .text_re {
        margin: 20px 0px;
      }
    }
  }
</style>

<style lang="less" scoped>
  .refundArea {
    font-size: 14px;
    color: #606266;
    .refundRadio {
      margin: 10px 0;
      & > span {
        margin-right: 10px;
      }
    }
    .refund-form {
      display: flex;
      // flex-wrap: wrap;
      /deep/ .el-form-item {
        // width: 180px;
        flex: 1;
      }
    }
  }
</style>
